import * as React from 'react';
import Badge from './components/badge/badge';

import { btnUnderlineHover } from './section-0.module.css';

function Section0({ pageData }) {
  return (
    <section className="w-full mobile:px-[15px]">
      <div
        className="
                xl:w-[1256px]
                lg:w-[900px]
                smMobile:w-full
                smMobile:px-[12px]
                my-0
                mx-auto
            "
      >
        <a
          href="/mission"
          className={`
                    ${btnUnderlineHover}
                    inline-block
                    leading-[30px]
                    lg:text-18
                    mobile:text-17
                    lg:mt-[56px]
                    mobile:mt-[45px]
                    lg:mb-[39px]
                    mobile:mb-[10px]
                    text-orange
                    font-roboto
                    cursor-pointer
                  `}
        >
          &lt;&lt; Go Back
        </a>
        <h3
          className="
                    text-neutral-400
                    font-roboto
                    font-bold
                    text-24
                    mobile:text-18
                    leading-[79px]
                    lg:mb-[10px]
                    mobile:mb-[15px]
                "
        >
          {pageData.sponsorCornerHeading}
        </h3>
        <h2
          className="
                    font-rubik
                    text-primary
                    text-40
                    mobile:text-32
                    leading-[50px]
                    font-bold
                    mobile:w-full
                "
        >
          {pageData.sponsorTitle}
        </h2>
        <div
          className="
                    flex
                    shrink-0
                    lg:items-normal
                    space-x-[60px]
                    mobile:space-x-0
                    xl:h-[150px]
                    lg:h-[167px]
                    lg:mt-[30px]
                    mobile:mt-[20px]
                    pl-0
                    mobile:flex-col
                "
        >
          <p
            className="
                        shrink-0
                        text-neutral-400
                        text-18
                        mobile:text-17
                        font-roboto
                        leading-[30px]
                        xl:basis-[1256px]
                        lg:basis-[900px]
                        mobile:mt-[50px]
                    "
          >
            {pageData.sponsorText.sponsorText}
          </p>
        </div>
        <div
          className="
                    flex
                    xl:mt-[70px]
                    lg:mt-0
                    mobile:mt-[36px]
                    shrink-0
                    xl:w-full
                    lg:basis-[304px]
                    mobile:flex-col
                    mobile:gap-[100px]
                "
        >
          {pageData.sponsorBadges.map((badge, idx) => (
            <Badge
              key={badge.file.url}
              imgIdx={idx}
              alt={badge.description}
              img={badge.file.url}
              text={pageData.sponsorBadgesText[idx]}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Section0;
